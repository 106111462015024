import React from "react";
import { Helmet } from 'react-helmet';

import ContactForm from "../components/contactForm";
import ReviewsBanner from "../components/reviewsBanner";

function CustomTile() {
    return (
        <>
            <Helmet>
                <title>Custom Tile</title>
                <meta name="description" content="Elevate your space with expert custom tile installation by Topline Kitchen and Bath in Woodland, CA. We combine precision and artistry to create timeless elegance with every tile." />
            </Helmet>
            <div className="tile border-b-4 border-b-dd-brown pb-10">
                <div className="max-w-7xl m-auto pt-40 pb-16">
                    <div className="text-white text-center">
                        <div className="md:text-6xl text-5xl font-bold drop-shadow-md">
                            Custom Tile Services
                        </div>
                    </div>
                </div>
            </div>
            <div className="max-w-7xl grid md:grid-cols-2 gap-10 pt-24 pb-16 px-4 m-auto">
                <div className="">
                    <div className="text-6xl text-center font-semibold">
                        Remodeling Services
                    </div>
                    <div className="pt-10">
Transform your space into a work of art with Topline Kitchen And Bath, where excellence in custom tile installation is not just our promise, but our passion. Our expertise in custom tile work extends beyond mere installation; we create enduring beauty and distinctive elegance in every tile we lay.
<br/><br/>
Expertise in Custom Tile Installation:
<br/><br/>
Our seasoned professionals bring a wealth of knowledge and skill to every project. Whether it’s the intricate patterns of ceramic or the classic luxury of marble, we ensure that every tile is placed with precision and care. Our attention to detail means your floors, walls, and custom tile showers not only look impeccable but also stand the test of time.
<br/><br/>
Wide Range of Custom Tile Options:
<br/><br/>

Your vision is our canvas. We offer an extensive selection of custom tile options to suit every style and functional need. From the sleek sophistication of modern designs to the timeless appeal of classic styles, our range ensures that your space reflects your unique taste. Our experts are here to guide you through the selection process, ensuring that your choice of tiles perfectly complements your space and lifestyle.
<br/><br/>
Dedicated to Superior Service:
<br/><br/>
At Topline Kitchen and Bath, we pride ourselves on not just meeting but exceeding your expectations. From the initial consultation to the final installation, we are committed to delivering unparalleled service. We understand the importance of your investment, and we work diligently to ensure every aspect of our service aligns with your needs and preferences.
<br/><br/>
Embrace the elegance and versatility of custom tiles with Topline Kitchen and Bath. Contact us in Woodland, CA, to discover more about our custom tile installation services, including our exquisite ceramic and marble options. Let us help you bring your vision to life with the artistry and craftsmanship that your space deserves.

                    </div>
                </div>
                <div className="md:p-10">
                    <ContactForm bgColor="bg-gray-100" headerText="Craft Your Unique Space - Inquire About Our Custom Tile Services!"/>
                </div>
            </div>
            <div className="pb-16">
                <div className="max-w-7xl m-auto grid md:grid-cols-4 gap-4 mt-16 px-5">
                    <ReviewsBanner/>
                </div>
            </div>
        </>
    );
}

export default CustomTile;
