import React from "react";
import { Helmet } from 'react-helmet';

import ContactForm from "../components/contactForm";
import ReviewsBanner from "../components/reviewsBanner";

function Contact() {
    return (
        <>
            <Helmet>
                <title>Contact Us</title>
                <meta name="description" content="Contact Topline Kitchen and Bath" />
            </Helmet>
            <div className="hero border-b-4 border-b-dd-brown pb-10">
                <div className="max-w-7xl m-auto pt-40 pb-16">
                    <div className="text-white text-center">
                        <div className="md:text-6xl text-5xl font-bold drop-shadow-md">
                            Contact Us
                        </div>
                    </div>
                </div>
            </div>
            <div className="max-w-7xl grid md:grid-cols-2 gap-10 px-4 pt-24 pb-16 m-auto">
                <div className="">
                    <div className="text-5xl text-center font-semibold">
                        Topline Kitchen and Bath LLC
                    </div>
                    <div className="pt-10">
                        530-845-0140<br/>
                    </div>
                </div>
                <div className="md:px-10">
                    <ContactForm bgColor="bg-gray-100" headerText="Ready to Reimagine Your Space? Contact Us for Expert Remodeling."/>
                </div>
            </div>
            <div className="pb-16">
                <div className="max-w-7xl m-auto grid md:grid-cols-4 gap-4 mt-16 px-5">
                    <ReviewsBanner/>
                </div>
            </div>
        </>
    );
}

export default Contact;
