import React, { useEffect } from "react";
import {
    Routes,
    Route,
    useLocation,
} from "react-router-dom";


import Layout from "./components/layout/Layout";

import Home from "./pages/Home";
import Reviews from "./pages/Reviews";
import Contact from "./pages/Contact";
import Remodeling from "./pages/Remodeling";
import Bathroom from "./pages/Bathroom";
import CustomTile from "./pages/CustomTile";
import Flooring from "./pages/Flooring";
import Kitchen from "./pages/Kitchen";
import Services from "./pages/Services";

import ReactGA from "react-ga4";
const TRACKING_ID = "G-54SBCQV9CM";
function App() {
    const location = useLocation();
    ReactGA.initialize(TRACKING_ID);
    useEffect(() => {
        ReactGA.set({ page: location.pathname });
    }, [location]);
    return (
        <Layout>
            <Routes location={location}>
                <Route exact path="/" element={<Home />} />
                <Route exact path="/services" element={<Services />} />
                <Route exact path="/remodeling" element={<Remodeling />} />
                <Route exact path="/bathroom" element={<Bathroom />} />
                <Route exact path="/custom-tile" element={<CustomTile />} />
                <Route exact path="/flooring" element={<Flooring />} />
                <Route exact path="/kitchen" element={<Kitchen />} />
                <Route exact path="/reviews" element={<Reviews />} />
                <Route exact path="/contact" element={<Contact />} />
            </Routes>
        </Layout>
    );
}

export default App;
