import React from "react";
import { Helmet } from 'react-helmet';

import ContactForm from "../components/contactForm";
import ReviewsBanner from "../components/reviewsBanner";

function Flooring() {
    return (
        <>
            <Helmet>
                <title>Flooring</title>
                <meta name="description" content="Transform your home with Topline Kitchen and Bath’s expert flooring solutions in Woodland, CA. We offer high-quality, custom installations of hardwood, tiles, and carpets, blending durability with style." />
            </Helmet>
            <div className="flooring border-b-4 border-b-dd-brown pb-10">
                <div className="max-w-7xl m-auto pt-40 pb-16">
                    <div className="text-white text-center">
                        <div className="md:text-6xl text-5xl font-bold drop-shadow-md">
                            Flooring Services
                        </div>
                    </div>
                </div>
            </div>
            <div className="max-w-7xl grid md:grid-cols-2 gap-10 pt-24 pb-16 px-4 m-auto">
                <div className="">
                    <div className="text-6xl text-center font-semibold">
                        Remodeling Services
                    </div>
                    <div className="pt-10">
Step into a world of enduring beauty and unparalleled craftsmanship with Topline Kitchen And Bath. As experts in flooring solutions, we understand that your floors are not just the foundation of your home but also an expression of your style.
<br/><br/>
Unmatched Quality in Flooring Installation: 
<br/><br/>
Our commitment to excellence shines through in every project, whether it's installing elegant hardwood floors, contemporary tiles, or luxurious carpets. We specialize in creating floors that not only look stunning but are also built to withstand the test of time and daily life. Our team of skilled professionals uses only the highest quality materials and the latest techniques to ensure your floors are not just installed, but meticulously crafted to perfection.
<br/><br/>
Custom Solutions for Every Space:
<br/><br/>
At Topline Kitchen and Bath, we believe that every space is unique, and so are your flooring needs. From sleek and modern kitchen tiles to warm and inviting hardwood for your living spaces, we offer a wide range of options to suit every taste and requirement. Our expertise also extends to custom cabinet and countertop installations, ensuring a cohesive and harmonious design throughout your home.
<br/><br/>
Dedicated to Excellence in Service:
<br/><br/>
We pride ourselves on our attention to detail and our unwavering commitment to customer satisfaction. Our team works closely with you to understand your vision and bring it to life. Whether it’s a minor repair, a complete floor overhaul, or a bespoke stair installation, no job is too big or small for us. We approach every project with the same dedication, ensuring that you receive the best in both craftsmanship and service.
<br/><br/>
Choose Topline Kitchen and Bath for your flooring needs, and experience the difference that professional expertise and genuine passion can make. With us, you're not just renovating your floors; you're elevating your home.

                    </div>
                </div>
                <div className="md:p-10">
                    <ContactForm bgColor="bg-gray-100" headerText="Step into Style - Contact Us for Premier Flooring Solutions!"/>
                </div>
            </div>
            <div className="pb-16">
                <div className="max-w-7xl m-auto grid md:grid-cols-4 gap-4 mt-16 px-5">
                    <ReviewsBanner/>
                </div>
            </div>
        </>
    );
}

export default Flooring;
