import React from "react";
import { Helmet } from 'react-helmet';

import ContactForm from "../components/contactForm";
import ReviewsBanner from "../components/reviewsBanner";

function Reviews() {

    return (
        <>
            <Helmet>
                <title>Reviews</title>
                <meta name="description" content="Review of Topline Kitchen And Bath" />
            </Helmet>
            <div className="hero border-b-4 border-b-dd-brown pb-10">
                <div className="max-w-7xl m-auto pt-40 pb-16">
                    <div className="text-white text-center">
                        <div className="md:text-6xl text-5xl font-bold drop-shadow-md">
                            Real Stories, Real Transformations<br/>
                            Our Customer's Voice
                        </div>
                    </div>
                </div>
            </div>
            <div className="max-w-7xl grid md:grid-cols-2 px-4 gap-10 pt-24 pb-16 m-auto relative">
                <div className=" overflow-y-scroll md:h-[900px]">
                    <div className="bg-gray-100 m-2 p-2">
                        <div>⭐⭐⭐⭐⭐</div>
                        <div>Tims workmanship is superb. His dedication to perfection is something that cant be taught. His workmanship matches the very few that are at the top of their game. Tim did my entire home in solid oak. There were some complicated cuts and fits and he mastered it all because of his tireless commitment to the highest standards of flooring installations. The most important quality Tim has is his integrity. Never find a more honest guy.
                        </div>
                    </div>
                    <div className="bg-gray-100 m-2 p-2">
                        <div>⭐⭐⭐⭐⭐</div>
                        <div>Tim and his team are professional and provided a top notch install. BOOK HIM NOW!! My floors and tile back splash look amazing. He will be my go to for flooring, tile and anything else I can dream up. Thank you Tim!
                        </div>
                    </div>
                    
                    <div className="bg-gray-100 m-2 p-2">
                        <div>⭐⭐⭐⭐⭐</div>
                        <div>Topline Floors assisted with our bathroom remodel. They laid new tile floors, marble subways on the walls and an all new shower!
Tim and his crew also installed and LVP floor throughout our house and did the tile work in our kitchen. Tim and his crew do amazing work, we couldnt have asked for a better job!
                        </div>
                    </div>
                    
                    <div className="bg-gray-100 m-2 p-2">
                        <div>⭐⭐⭐⭐⭐</div>
                        <div>We had an amazing experience with Tim and crew at Topline. He really took the time to work with us to figure out what our needs were and how we could stay within our budget. Topline works quickly and efficiently and they have incredible attention to detail. They did a beautiful job on our laminate floors and stairs and were incredibly understanding with working around our pets. I will be recommending them to anyone looking for tile, laminate, hardwood, etc.
                        </div>
                    </div>
                    
                    <div className="bg-gray-100 m-2 p-2">
                        <div>⭐⭐⭐⭐⭐</div>
                        <div>I am completely amazed by the job that Tim and his crew have done for both my home, as well as my mothers house. They did a fantastic job professionally and in a timely manner. I will definitely use them for any further projects.
                        </div>
                    </div>
                    
                    <div className="bg-gray-100 m-2 p-2">
                        <div>⭐⭐⭐⭐⭐</div>
                        <div>Tim and crew did excellent work. Tim also made some design suggestions that nicely enhanced the appearance of the tile work.
                        </div>
                    </div>
                    
                    <div className="bg-gray-100 m-2 p-2">
                        <div>⭐⭐⭐⭐⭐</div>
                        <div>I normally do not post reviews but felt others should know about the outstanding performance of Topline Floors. From start to finish, Tim, Cassie, and the rest of the crew were professional, timely and responsive. Installing >2000 sq feet of flooring and baseboards after facing a complicated tile removal in less than 7 days is a feat in and of itself. We would welcome them back anytime to help us with another project. Thank you for all of your hard work. Our years of dreaming of hardwood floors in finally a reality.
                        </div>
                    </div>
                    
                    <div className="bg-gray-100 m-2 p-2">
                        <div>⭐⭐⭐⭐⭐</div>
                        <div>Job well done, good communication, turned up on time, and cleaned up. Professional.
                        </div>
                    </div>
                    
                    <div className="bg-gray-100 m-2 p-2">
                        <div>⭐⭐⭐⭐⭐</div>
                        <div>Tim the owner of Topline Flooring are very Professional, they strive for perfection on every job he and his crew do. I Highly recommend to anyone who is thinking of getting flooring or tile done.
                        </div>
                    </div>
                    <div className="bg-gray-100 m-2 p-2">
                        <div>⭐⭐⭐⭐⭐</div>
                        <div>Tim and Isaac with assists from Cassie removed carpeting in three bedrooms, a hallway and a stairway. They had to remove our upstairs banister and replace it after installing engineered hardwood floors. They also removed all the baseboards and after we decided mid-project to replace the older, splintering baseboards with MDF, these got painted and installed (thanks Cassie!).
<br/>
<br/>
Our flooring got back-ordered due to pandemic shortages but when it came in earlier than expected, Topline scheduled us right in. There were some scheduling issues but we were always kept informed and Isaac was there every day, right on time and worked solidly through very long days aided by Tim and other crew during the major install day. The attached pictures show the excellent detail work where new baseboards were fit into existing door frames and how the flooring "disappears" under the casings. We were pleased and impressed with the look Topline achieved.
<br/><br/>
Excellent attention to detail, to keeping our space cleaned up at the end of each day, and to respecting our home. We were very pleased with the workmanship and positive interactions we had with everyone involved and would certainly recommend Topline and use them again for any future work.
                        </div>
                    </div>

                    <div className="bg-gray-100 m-2 p-2">
                        <div>⭐⭐⭐⭐⭐</div>
                        <div>Theyve done multiple jobs for us and everything comes out beautiful! Super friendly, professional, efficient, and does GREAT work! Do yourself a favor and hire them!
                        </div>
                    </div>
                    
                    
                    <div className="bg-gray-100 m-2 p-2">
                        <div>⭐⭐⭐⭐⭐</div>
                        <div>If you want high quality craftsmanship that exceeds your expectation then Topline Floors should be your 1st and only call. Tim made the experience easy and transparent. I just purchased a new home, after meeting with Tim I have him a key and he did the rest. More than what I initially was planning on. I would recommend Tim and his crew to anyone.
                        </div>
                    </div>
                    

                    
                    
                    <div className="bg-gray-100 m-2 p-2">
                        <div>⭐⭐⭐⭐⭐</div>
                        <div>We truly could not be happier with the service we received. They were professional during the entire process, punctual and extremely knowledgeable. We had previous tile work done that ended up really bad. Cracked tile, a wall that could be pushed in and out and very sharp corners. The entire process with Tim and Cassie was completely different than my previous experience. They offered their professional opinion and I am SO glad we went that route instead of what I originally chose tile wise, it just made more sense. I cant thank them enough for the great experience and we look forward to our next project because we finally found a reliable and trustworthy company that does amazing work!
                        </div>
                    </div>
                    
                    <div className="bg-gray-100 m-2 p-2">
                        <div>⭐⭐⭐⭐⭐</div>
                        <div>We had the best experience with Tim, he was very understanding of what we wanted and got it done perfectly. I highly recommend!
                        </div>
                    </div>
                    
                    <div className="bg-gray-100 m-2 p-2">
                        <div>⭐⭐⭐⭐⭐</div>
                        <div>I am a bathroom designer and Tim and his team did our bathrooms projects for us while he lived in California As a designer, my clients are important to me and the quality of my contractors workmanship reflects on my reputation too.<br/>
Tims work was impeccable. He was on time, on schedule and on budget. A couple of the projects were complicated, but Tim took his time to do it right. He even offered suggestions on new techniques and materials that elevated the projects.<br/>
I also love that Tim uses superior methods of tile application that are better when it comes to waterproofing and longevity of the bathroom.
I was so sad when Tim and his family moved out of state. Maybe I can convince him to visit California to do some more of my projects. ;)
                        </div>
                    </div>
                    
                    <div className="bg-gray-100 m-2 p-2">
                        <div>⭐⭐⭐⭐⭐</div>
                        <div>Tim did a fantastic job on our tile! He worked quickly, was professional and respectful. He even had some suggestions on layout. I am thrilled with how it turned out.
                        </div>
                    </div>
                    
                    
                    <div className="bg-gray-100 m-2 p-2">
                        <div>⭐⭐⭐⭐⭐</div>
                        <div>Love them!
                        </div>
                    </div>
                    
                    <div className="bg-gray-100 m-2 p-2">
                        <div>⭐⭐⭐⭐⭐</div>
                        <div>Topline Kitchen & Bath did an amazing job on my tile shower. I wasn't completely sure what I wanted but they were willing to work with me on ideas and so helpful along the way. I changed my mind about half way through and Tim was able to make the necessary adjustments to bring my vision to life. I honestly can't thank them enough for how great this entire process will as. My new tile shower has exceeded my expectations!
                        </div>
                    </div>
                    

                </div>
                <div className="md:p-10">
                    <ContactForm bgColor="bg-gray-100" headerText="Ready to Reimagine Your Space? Contact Us for Expert Remodeling."/>
                </div>
            </div>
            <div className="pb-16">
                <div className="max-w-7xl m-auto grid md:grid-cols-4 gap-4 mt-16 px-5">
                    <ReviewsBanner/>
                </div>
            </div>
        </>
    );
}

export default Reviews;
