import React from "react";
import { Helmet } from 'react-helmet';
import { Link } from "react-router-dom";

import ContactForm from "../components/contactForm";
import ReviewsBanner from "../components/reviewsBanner";

function Home() {
    
    return (
        <>
            <Helmet>
                <title>Topline Kitchen & Bath</title>
                <meta name="description" content="Topline Kitchen and Bath in Woodland, CA offers expert custom tile and flooring installations. We blend quality materials with skilled craftsmanship to create beautiful, durable spaces that reflect your style." />
            </Helmet>
            <div className="hero min-h-screen border-b-4 border-b-dd-brown pb-10">
                <div className="max-w-7xl m-auto grid md:grid-cols-2 gap-12 md:pt-32 pt-24 md:pl-24">
                    <div className="mx-5 text-white">
                        <div className="md:text-6xl text-5xl font-bold drop-shadow-md">
                            Transforming Spaces, Exceeding Expectations
                        </div>
                        <div className="text-3xl drop-shadow-md pt-10 leading-10">
                            We specialize in bringing your vision to life. From elegant remodeling to custom tile work, and cabinet installation - our skilled team is dedicated to delivering excellence.

                        </div>
                    </div>
                    <ContactForm bgColor="bg-white" headerText="Ready to Reinvent Your Space? Get Started with a Free Consultation!"/>
                </div>
            </div>
            <div>
                <div className="max-w-7xl m-auto grid md:grid-cols-4 gap-4 mt-16 px-5">
                    <ReviewsBanner/>
                </div>
            </div>
            <div className="max-w-7xl m-auto px-10 pt-24">
                <div className="text-5xl font-semibold text-center pb-10">
                    Our Services
                </div>
                <div className="grid md:grid-cols-3 gap-14">
                    <div className="flex flex-col justify-between">
                        <Link to="/remodeling">
                            <img src="/images/remodeling.jpg" alt="remodeling"/>
                        </Link>
                        <div className="py-2 text-xl font-semibold">
                            <Link to="/remodeling" className="hover:underline">
                                Remodeling Services
                            </Link>
                        </div>
                        <div className="py-2 pb-6 font-base">Discover unparalleled remodeling expertise with Topline Kitchen & Bath LLC, where we transform your living spaces with a commitment to excellence and superior craftsmanship. Our home transformations are tailored to your unique style and functional needs, ensuring your vision is realized with precision and care.</div>
                        <div className="text-center w-full">
                            <Link to="/remodeling" className="bg-3f-black hover:bg-dd-brown text-white text-2xl border-b-4 px-5 py-2 border-b-dd-brown w-full">Remodeling Services</Link>
                        </div>
                    </div>
                    <div className="flex flex-col justify-between">
                        <Link to="/flooring">
                            <img src="/images/flooring_leader.png" alt="remodeling"/>
                        </Link>
                        <div className="py-2 text-xl font-semibold">
                            <Link to="/flooring" className="hover:underline">
                                Flooring Services
                            </Link>
                        </div>
                        <div className="py-2 pb-6 font-base">Step into elegance with Topline Kitchen & Bath LLC’s flooring services, where we blend beauty with durability. Our team specializes in a variety of flooring solutions, ensuring each installation is a perfect match for your home’s style and functionality needs.</div>
                        <div className="text-center justify-self-end">
                            <Link to="/flooring" className="bg-3f-black hover:bg-dd-brown text-white text-2xl border-b-4 px-5 py-2 border-b-dd-brown">Flooring Services</Link>
                        </div>
                    </div>
                    <div className="flex flex-col justify-between">
                        <Link to="/custom-tile">
                            <img src="/images/tile_leader.png" alt="remodeling"/>
                        </Link>
                        <div className="py-2 text-xl font-semibold">
                            <Link to="/custom-tile" className="hover:underline">
                                Custom Tile Services
                            </Link>
                        </div>
                        <div className="py-2 pb-6 font-base">Elevate your spaces with Topline Kitchen & Bath LLC's custom tile services, where we combine artistic vision with expert craftsmanship. Our team excels in tile installations that enhance the beauty and character of your home.<br/><br/></div>
                        <div className="text-center">
                            <Link to="/custom-tile" className="bg-3f-black hover:bg-dd-brown text-white text-2xl border-b-4 px-5 py-2 border-b-dd-brown">Custom Tile Services</Link>
                        </div>
                    </div>
                    <div className="flex flex-col justify-between">
                        <Link to="/bathroom">
                            <img src="/images/bathroom_leader.png" alt="bathroom remodeling"/>
                        </Link>
                        <div className="py-2 text-xl font-semibold">
                            <Link to="/bathroom" className="hover:underline">
                                Bathroom Services
                            </Link>
                        </div>
                        <div className="py-2 pb-6 font-base">Embark on a bathroom transformation journey with Topline Kitchen & Bath LLC, where we blend luxury with functionality. Our tailored approach ensures your bathroom remodel meets your personal style and practical needs, creating a serene, inviting space.</div>
                        <div className="text-center">
                            <Link to="/bathroom" className="bg-3f-black hover:bg-dd-brown text-white text-2xl border-b-4 px-5 py-2 border-b-dd-brown">Bathroom Services</Link>
                        </div>
                    </div>
                    <div className="flex flex-col justify-between">
                        <Link to="/kitchen">
                            <img src="/images/kitchen_leader.png" alt="kitchen remodeling"/>
                        </Link>
                        <div className="py-2 text-xl font-semibold">
                            <Link to="/kitchen" className="hover:underline">
                                Kitchen Services
                            </Link>
                        </div>
                        <div className="py-2 pb-6 font-base">Revolutionize your culinary space with Topline Kitchen & Bath LLC’s kitchen remodeling services. We specialize in creating kitchens that are not just cooking spaces, but heartwarming areas where style, functionality, and personal taste blend seamlessly.</div>
                        <div className="text-center">
                            <Link to="/kitchen" className="bg-3f-black hover:bg-dd-brown text-white text-2xl border-b-4 px-5 py-2 border-b-dd-brown">Kitchen Services</Link>
                        </div>
                    </div>
                    <div className="flex flex-col justify-between">
                        <div>
                            <Link to="/contact">
                                <img src="/images/contact_us_leader.png" alt="contact us"/>
                            </Link>
                            <div className="py-2 text-xl font-semibold">
                                <Link to="/contact" className="hover:underline">
                                    Contact Us
                                </Link>
                            </div>
                            <div className="py-2 pb-6 font-base">Contact Us for your remodeling needs.
                            </div>
                        </div>
                        <div className="text-center">
                            <Link to="/contact" className="bg-3f-black hover:bg-dd-brown text-white text-2xl border-b-4 px-5 py-2 border-b-dd-brown">Contact Us</Link>
                        </div>
                    </div>
                </div>

            </div>
            <div className="max-w-7xl m-auto flex flex-col my-24 px-10">
                <div className="font-semibold">
                    Let's get started
                </div>
                <div className="grid md:grid-cols-2 gap-10">
                    <div className="flex flex-col">
                        <div className="font-semibold md:text-6xl text-4xl">
                            Flooring Installation and Replacement
                        </div>
                        <div className="md:text-3xl text-2xl py-5">
                            Replace Your Worn, Outdated Floors With Something New That Not Only Stands Up to Regular Foot Traffic as Well as Handles Drops, Spills and Stains With Ease
                        </div>
                        <Link to="/contact" className="bg-3f-black hover:bg-dd-brown text-white text-2xl border-b-4 px-5 py-2 border-b-dd-brown w-fit">Contact Us</Link>
                    </div>
                    <div className="flex flex-col justify-center">
                        <img className="rounded-lg overflow-hidden" src="/images/two_men.jpeg" alt="two men talking about computer"/>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Home;
