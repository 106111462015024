import React from "react";
import { Helmet } from 'react-helmet';

import ContactForm from "../components/contactForm";
import ReviewsBanner from "../components/reviewsBanner";

function Kitchen() {
    return (
        <>
            <Helmet>
                <title>Kitchen</title>
                <meta name="description" content="Transform your kitchen into a masterpiece with Topline Kitchen and Bath in Woodland, CA. We specialize in personalized kitchen remodeling, combining innovative design with meticulous craftsmanship to create spaces that blend style and functionality perfectly." />
            </Helmet>
            <div className="kitchen border-b-4 border-b-dd-brown pb-10">
                <div className="max-w-7xl m-auto pt-40 pb-16">
                    <div className="text-white text-center">
                        <div className="md:text-6xl text-5xl font-bold drop-shadow-md">
                            Kitchen Services
                        </div>
                    </div>
                </div>
            </div>
            <div className="max-w-7xl grid md:grid-cols-2 gap-10 pt-24 pb-16 px-4 m-auto">
                <div className="">
                    <div className="text-6xl text-center font-semibold">
                        Remodeling Services
                    </div>
                    <div className="pt-10">
Welcome to a world where culinary spaces are transformed into masterpieces with Topline Kitchen And Bath. Our kitchen remodeling services are designed to turn your vision of the perfect kitchen into a stunning reality.
<br/><br/>
Crafting Your Dream Kitchen:
<br/><br/>
Your kitchen is more than just a cooking space; it's the heart of your home, a place where memories are made and shared. Our mission is to create a kitchen that reflects your personal style and caters to your lifestyle. Whether you dream of a modern, sleek kitchen or a warm, traditional space, our expert team is here to make it happen. With a blend of innovative design and meticulous craftsmanship, we ensure that every element of your kitchen, from cabinets to countertops, is perfectly executed.
<br/><br/>
Consultation and Customization:
<br/><br/>
Our process begins with a detailed consultation where we listen to your ideas, preferences, and needs. Our home renovation experts bring their wealth of experience to provide insightful suggestions and innovative solutions. This collaborative approach ensures that your new kitchen is not only visually stunning but also highly functional. 
<br/><br/>
Attention to Detail:
<br/><br/>
At Topline Kitchen And Bath, we understand that the devil is in the details. Our experienced general contractors have a keen eye for detail, ensuring that every aspect of your kitchen remodeling, from floor installation to cabinetry, is done with precision and care. This meticulous approach extends to every aspect of our work, guaranteeing a finish that is not only beautiful but also of the highest quality.
<br/><br/>
Proudly serving the residents of Woodland, CA, Topline Kitchen And Bath is committed to delivering affordable, high-quality home renovations. With a track record of successful residential renovations in the region, we are excited to partner with you on your kitchen remodeling journey. Trust us to bring your dream kitchen to life, a space where style meets functionality, creating the perfect backdrop for your life’s best moments.

                    </div>
                </div>
                <div className="md:p-10">
                    <ContactForm bgColor="bg-gray-100" headerText="Design Your Dream Kitchen - Contact Us for a Remodeling Consultation!"/>
                </div>
            </div>
            <div className="pb-16">
                <div className="max-w-7xl m-auto grid md:grid-cols-4 gap-4 mt-16 px-5">
                    <ReviewsBanner/>
                </div>
            </div>
        </>
    );
}

export default Kitchen;
