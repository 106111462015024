import React from "react";
import { Link } from "react-router-dom";

function ReviewsBanner() {
    return (
        <>
            <Link to="https://www.yelp.com/biz/top-line-construction-and-flooring-summerville" target="_blank">
                <div className="grid grid-cols-2 shadow-reviews p-2">
                    <div><img src="/images/yelp.png" alt="yelp 5 star review"/></div>
                    <div className="flex flex-col text-center pt-4 font-semibold text-md">
                        <div>5/5</div>
                        <div>Yelp Reviews</div>
                    </div>
                </div>
            </Link>
            <Link to="https://www.facebook.com/p/Top-Line-Construction-Flooring-100087824271135/?paipv=0&eav=AfaN3YNu_tcQpFZSMpXUhepIAWX9P3rzaSpO9byM5gh2zEeOMsMePycuf3cDfXUzSZM&_rdr" target="_blank">
                <div className="grid grid-cols-2 shadow-reviews p-2">
                    <div><img src="/images/facebook.png" alt="google 5 star review"/></div>
                    <div className="flex flex-col text-center pt-4 font-semibold text-md">
                        <div>5/5</div>
                        <div>Facebook Reviews</div>
                    </div>
                </div>
            </Link>
            <Link to="https://ios.nextdoor.com/pages/top-line-construction-flooring-hot-springs-sd/" target="_blank">
                <div className="grid grid-cols-2 shadow-reviews p-2">
                    <div><img src="/images/nextdoor.png" alt="google 5 star review"/></div>
                    <div className="flex flex-col text-center pt-4 font-semibold text-md">
                        <div>5/5</div>
                        <div>NextDoor Reviews</div>
                    </div>
                </div>
            </Link>
            <Link to="https://local.yahoo.com/info-222127234-Top-Line-Construction-and-Flooring?p=Top+Line+Construction+and+Flooring&selectedId=222127234&ei=UTF-8" target="_blank">
                <div className="grid grid-cols-2 shadow-reviews p-2">
                    <div><img src="/images/yahoo.png" alt="google 5 star review"/></div>
                    <div className="flex flex-col text-center pt-4 font-semibold text-md">
                        <div>5/5</div>
                        <div>Yahoo Reviews</div>
                    </div>
                </div>
            </Link>
        </>
    )
}

export default ReviewsBanner;
