import React from "react";

function Footer() {
    return (
        <footer className="bg-3f-black text-white">
            <div className="max-w-7xl m-auto grid md:grid-cols-6 grid-cols-3 py-16 md:px-5 px-10">
                <div>
                    <div className="font-semibold">Contactor License</div>
                    <div>Contact Us</div>
                </div>
                <div>
                    <div className="font-semibold">Contact</div>
                    <div>530-845-0140</div>
                </div>

                <div className="col-span-6 pt-12">
                    Topline Kitchen & Bath LLC © All Rights Reserved.
                </div>
            </div>
        </footer>
    );
}

export default Footer;
