import React, { useState, useRef } from "react";
import emailjs from 'emailjs-com';

function ContactForm({
    headerText,
    bgColor,
}) {
    const [formSubmitted, setFormSubmitted] = useState(false);
    const form = useRef();

    const handleOnClick = (e) => {
        e.preventDefault();
        emailjs.init('_oIaaUvfVZQs-C997');
        emailjs.sendForm('service_dpdo0re', 'template_pcwxv48', form.current)
            .then((result) => {
                setFormSubmitted(true);
            }, (error) => {
                console.log(error.text);
            });
    }
    return (
        <>
        { formSubmitted ?

            <div className={`px-10 py-8 gap-4 ${bgColor} text-2xl text-center justify-center m-auto`}>
                Thank You, well contact you shortly!
            </div>

        :
            <form ref={form} onSubmit={handleOnClick} >
                <div className={`grid grid-cols-2 px-10 py-8 gap-4 ${bgColor}`}>
                    <div className="col-span-2 md:text-4xl text-3xl font-bold md:mb-10">
                        { headerText }
                    </div>
                    <div className="flex flex-col">
                        <label className="font-bold">You Name
                            <input name="name" className="ring-2 ring-black bg-white rounded-lg p-2 w-full"/>
                        </label>
                    </div>
                    <div className="flex flex-col">
                        <label className="font-bold">Email Address
                        <input name="email" className="ring-2 ring-black bg-white rounded-lg p-2 w-full"/>
                        </label>
                    </div>
                    <div className="flex flex-col">
                        <label className="font-bold">Phone Number
                        <input name="phone" className="ring-2 ring-black bg-white rounded-lg p-2  w-full"/>
                        </label>
                    </div>
                    <div className="flex flex-col">
                        <label className="font-bold">Contact Method
                        <select className="border-2 border-black text-xl p-1 rounded-lg w-full" name="preferred_contact">
                            <option value="">Select an option</option>
                            <option value="email">Email</option>
                            <option value="phone">Phone</option>
                        </select>
                        </label>
                    </div>
                    <div className="col-span-2 flex flex-col">
                        <label className="font-bold">Tell us about your project
                        <textarea className="border-2 border-black text-xl px-5 py-2 rounded-lg w-full h-52"
                            name="message"/>
                            </label>
                    </div>
                    <div className="col-span-2 flex flex-col">
                        <button type="submit" className="bg-3f-black hover:bg-dd-brown hover:cursor-pointer w-fit text-white text-2xl border-b-4 px-5 py-2 border-b-dd-brown">
                            Contact Us
                        </button>
                    </div>
                </div>
            </form>
        }
        </>
    )
}

export default ContactForm;
